import { useEffect, useState } from "react";
import { IDashboardStats } from "../../models/Dashboard";
import DashboardTile from "./DashboardTile";
import classNames from "../../utils/classNames";
import PriceFormat from './../../utils/priceFormat';
import { DateTime } from "luxon";
import { time } from "console";
import { ArrowTrendingDownIcon, ArrowTrendingUpIcon } from "@heroicons/react/24/outline";

const tabs = [
  { name: 'Month', value: 'month' },
  { name: 'All Time', value: 'all-time' },
]

export default function PostcodePricesTable({ dashboardStats }: { dashboardStats: IDashboardStats }) {
  const [timeframe, setTimeframe] = useState("month");
  const [stats, setStats] = useState<{ [key: string]: number } | undefined>(undefined);
  const [lastMonth, setLastMonth] = useState<{ [key: string]: number } | undefined>(undefined);

  useEffect(() => {
    if (dashboardStats) {
      switch (timeframe) {
        case "month":
          // Access the stats for the last calendar month
          setLastMonth(dashboardStats.monthlyIncomePerOutcode[DateTime.now().startOf('month').minus({ days: 1 }).toFormat("LLLL yy")]);
          setStats(dashboardStats.priceByPostcodeMonth);
          break;
        case "all-time":
          setStats(dashboardStats.priceByPostcode);
          break;
      }
    }
  }, [dashboardStats, timeframe])

  return (
    <DashboardTile span={2}>
      <div className="p-3">
        <div className="mb-3 w-full flex justify-between">
          <div className="prose">
            <h3>Average Postcode Area Price</h3>
          </div>

          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                defaultValue={tabs.find((tab) => tab?.value === timeframe)?.name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-6" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => setTimeframe(tab.value)}
                      className={classNames(
                        tab.value === timeframe
                          ? 'border-indigo-500 text-gp-blue-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 py-1 px-1 text-sm font-medium'
                      )}
                      aria-current={tab.value === timeframe ? 'page' : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

        </div>

        <div className="mt-6">
          <div className="flex justify-between">
            <p>Outcode</p>
            <p>Price</p>
          </div>

          <hr />

          <div className="mt-4 flex flex-col divide-y gap-y-2">
            {stats && Object.keys(stats).map((key) => {
              if (stats[key] !== 0) {
                return (
                  <div key={key} className="flex justify-between pt-2 font-semibold">
                    <p className="">{key}</p>
                    <div className="flex gap-x-2 items-center">
                      {timeframe === "month" && lastMonth && lastMonth[key] >= 0 && (
                        <>
                          {/* <p className="text-sm text-gray-500">Last Month: {PriceFormat(lastMonth[key])}</p> */}
                          {lastMonth[key] > stats[key] ? (
                            <ArrowTrendingDownIcon className="h-5 w-5 text-red-600 rotate-[30]" />
                          ) : (
                            <ArrowTrendingUpIcon className="h-5 w-5 text-green-600 -rotate-[30]" />
                          )}
                        </>
                      )}
                      <p className="font-bold text-xl text-right text-gp-blue-600">{PriceFormat(stats[key])}</p>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>

      </div>
    </DashboardTile>
  )

}