import { useEffect, useState } from "react"
import { usePageNameContext } from "../../contexts/PageTitleContext";
import { CleanedUkMap } from "./CleanedUkMap";


const selectedCounties = [
  "Aberdeenshire",
  "Cornwall",
  "Dorset",
  "Swansea",
];

export function Test() {
  const { setInfo } = usePageNameContext();
  const [selectedCounty, setSelectedCounty] = useState<string | null>(null);

  useEffect(() => {
    setInfo({
      name: "Test",
      desc: "If you don't think you should be here, you shouldn't be here.",
    });
  }, []);

  const handleOnClick = (title: string) => {
    console.log(title);
    setSelectedCounty(title);

  };

  return (
    <div className="mt-4">
      <CleanedUkMap handleOnClick={handleOnClick} selectedCounties={selectedCounties} />
    </div>
  )
}