import { useEffect, useState } from "react";

import useEbayEngineData from "../../../data/useEbayEngineData";
import { Category } from "../../../models/Part";
import { IEbayEngineData, IEbayEngineDataResponse, IVehicle } from "../../../models/Vehicle";
import Card from "../../shared/Card/Card";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import PriceFormat from './../../../utils/priceFormat';
import PopOutBox from "../../shared/PopOutBox";
import AwaitingInventoryPrice from "../../awaitingInventory/AwaitingInventoryPrice";
import AwaitingInventoryVfePrice from "../../awaitingInventory/AwaitingInventoryVfePrice";

enum Condition {
  "Good" = 0.55,
  "Poor" = 0.4,
  "Heavy Collison Damage" = 0.25
}

const ValueSquare = ({ label, value, classes, children = null }: { label: string, value: string, classes: string, children?: any }) => {
  return (
    <div className={`p-2 gap-y-1 flex flex-col justify-center items-center border border-gray-400 ${classes}`}>
      <p className="text-lg font-medium text-gray-500">{label}</p>
      {value && <p className="text-lg font-medium text-gray-900">{value}</p>}
      {children}
    </div>
  )
}

const Tile = ({label, color, value, children}:{label: string, color: string, value?: string, children?: any}) => (
  <div className={`border border-slate-500 px-2 py-1 shadow-md rounded bg-${color}-100 grow pt-2 pb-4 max-w-fit`}>
    <p className={`mx-2 text-center text-${color}-600 font-semibold mb-2`}>{label}</p>
    <div className="flex flex-col justify-center">
      {
        value ? <p className={`font-bold text-center my-2 text-2xl text-${color}-600 mx-2`}>{value}</p> : null
      }
      {
        children ? <div className="flex flex-row justify-center">{children}</div> : null
      }
    </div>
  </div>
);

interface PartsValueProps {
  vehicle: IVehicle;
  leadId: string | undefined;
  categories: Category[];
  selected: Category[];
  handleSelectTopCategories: any;
}

export default function PartsValue({ vehicle, leadId, categories, selected, handleSelectTopCategories }: PartsValueProps) {
  const { ebayEngineData } = useEbayEngineData();
  const [condition, setCondition] = useState<Condition>(Condition.Good);
  const [fees, setFees] = useState<number>();
  const [profit, setProfit] = useState<number>();
  const [engineWorldWideTotal, setEngineWorldWideTotal] = useState<number>();
  const [engineData, setEngineData] = useState<IEbayEngineData | null>(null);
  const [gearboxPrice, setGearboxPrice] = useState<number>();
  const [gearboxMargin, setGearboxMargin] = useState<number>();
  const [ebayEngineAndGearbox, setEbayEngineAndGearbox] = useState<number>();
  const [totalParts, setTotalParts] = useState<number>(0);
  const [totalPartsPrice, setTotalPartsPrice] = useState<number>(0);
  const [totalPartsMargin, setTotalPartsMargin] = useState<number>(0);
  const [selectedPrice, setSelectedPrice] = useState<number>(0);
  const [selectedMargin, setSelectedMargin] = useState<number>(0);
  const [selectedTime, setSelectedTime] = useState<number>(0);
  const [selectedAndExportMargin, setSelectedAndExportMargin] = useState<number>(0);

  const salvageWidth = "w-48"
  const exportWidth = "w-36"

  const salvagePercentage = (percentage: number) => {
    return PriceFormat(vehicle.valuationTradePoor! * percentage);
  }

  const conditionButtonClasses = (conditionSelected: Condition) => {
    if (condition !== conditionSelected) {
      return "!bg-white !text-gray-900 !border !border-gray-900";
    }
  }

  const handleCondition = (condition: Condition) => {
    const tempFees = (vehicle.valuationTradePoor! * condition) * 0.1;
    const tempProfit = (vehicle.valuationTradePoor! * condition) - tempFees;

    setFees(tempFees);
    setProfit(tempProfit);
    setCondition(condition);
  }

  const setTopCategories = (i: number) => {
    // const topCategories = categories?.slice(0, i);
    const topCategories = categories?.sort((a, b) => b.category.averageMargin - a.category.averageMargin).slice(0, i);
    // console.log(topCategories);
    handleSelectTopCategories(topCategories);
  }

  useEffect(() => {
    //Set fees and profit
    if (vehicle.valuationTradePoor) {
      handleCondition(Condition.Good);
    }

    //Get ebay engine data
    if (vehicle.ebayEnginePrice) {
      const data = {
        make: vehicle.combinedMake,
        engineCode: vehicle.engineModelCode,
      }

      ebayEngineData.mutate(data, {
        onSuccess: (data) => {
          const engineDataResponse = data as IEbayEngineDataResponse;
          setEngineData(engineDataResponse.data);
        },
      })
    }

    //Set engine worldwide total
    const engine = parseInt(vehicle.enginesWorldwideEngineOffer ? vehicle.enginesWorldwideEngineOffer : "0");
    const frontEnd = parseInt(vehicle.enginesWorldwideVfeOffer ? vehicle.enginesWorldwideVfeOffer : "0");
    setEngineWorldWideTotal(engine + frontEnd);

    //Set gearbox price and margin
    const gearboxCategory = categories?.find((category) => category.category.name === "Gearbox");
    if (gearboxCategory) {
      setGearboxPrice(gearboxCategory.category.averagePrice);
      setGearboxMargin(gearboxCategory.category.averageMargin);
      setEbayEngineAndGearbox(gearboxCategory.category.averagePrice + vehicle.ebayEnginePrice!);
    } else {
      setEbayEngineAndGearbox(vehicle.ebayEnginePrice ?? 0);
    }

    //set parts stats
    let totalPrice = 0, totalMargin = 0;
    categories?.forEach((category) => {
      totalPrice += category.category.averagePrice;
      totalMargin += category.category.averageMargin;
    });
    setTotalParts(categories?.length!);
    setTotalPartsPrice(totalPrice);
    setTotalPartsMargin(totalMargin);

  }, [vehicle]);

  useEffect(() => {
    setSelectedPrice(selected.reduce((a, b) => a + b.category.averagePrice, 0));
    setSelectedMargin(selected.reduce((a, b) => a + b.category.averageMargin, 0));
    setSelectedTime(selected.reduce((a, b) => a + b.category.disTime, 0) + selected.reduce((a, b) => a + b.category.whTime, 0));

    var selectedMinusExportCategories = selected.filter((category) =>
      category.category.name !== "Manual Engine" &&
      category.category.name !== "Automatic Engine" &&
      category.category.name !== "ECU Engine" &&
      category.category.name !== "Engine" &&
      category.category.name !== "Engine Cover" &&
      category.category.name !== "Engine Inlet Manifold" &&
      category.category.name !== "Engine Mounting Bracket" &&
      category.category.name !== "Engine Sump" &&
      category.category.name !== "Gearbox" &&
      category.category.name !== "Alternator" &&
      category.category.name !== "Turbo" &&
      category.category.name !== "Air Conditioning Compressor"
    );
    setSelectedAndExportMargin(selectedMinusExportCategories.reduce((a, b) => a + b.category.averageMargin, 0) + engineWorldWideTotal!);
  }, [selected])

  return (
    <Card bodyClassName="p-4 flex flex-col divide-y gap-y-4">

      <div className="flex justify-center gap-x-8">
        {/* Salvage Row */}
        {!vehicle.valuationTradePoor ? (
          <ValueSquare label={"No Salvage Value"} value={""} classes={`${salvageWidth} h-20`} />
        ) : (
          <>
            <div className="flex flex-row flex-wrap gap-4"> 
              {
                !vehicle.valuationTradePoor ? <Tile label="" value="No Salvage Value" color="red"/>
                :
                <>
                  <Tile label="Salvage Summary" color="red">
                    <div className="text-center">
                      <p className="text-lg font-medium text-gray-900">{salvagePercentage(1)}</p>
                      <p className="text-lg font-medium text-gray-900"><span className="text-base text-gray-500">55% </span> {salvagePercentage(0.55)}</p>
                      <p className="text-lg font-medium text-gray-900"><span className="text-base text-gray-500">40% </span> {salvagePercentage(0.4)}</p>
                      <p className="text-lg font-medium text-gray-900"><span className="text-base text-gray-500">25% </span> {salvagePercentage(0.25)}</p>
                    </div>
                  </Tile>
                  <Tile label="Condition" color="red">
                    <div className="flex flex-col gap-2 mx-2">
                      <ButtonInput label={"Good"} isSubmit={false} onClick={() => handleCondition(Condition.Good)} classes={conditionButtonClasses(Condition.Good)} />
                      <ButtonInput label={"Poor"} isSubmit={false} onClick={() => handleCondition(Condition.Poor)} classes={conditionButtonClasses(Condition.Poor)} />
                      <ButtonInput label={"Heavy Damage"} isSubmit={false} onClick={() => handleCondition(Condition["Heavy Collison Damage"])} classes={conditionButtonClasses(Condition["Heavy Collison Damage"])} />
                    </div>
                  </Tile>

                  <Tile label="Salvage Fees" value={PriceFormat(fees)} color="red"/>
                  <Tile label="Total Profit" value={PriceFormat(profit)} color="red"/> 
                </>
              }
            </div>
          </>
        )}
      </div>
      <div className="flex justify-center flex-row flex-wrap gap-4 pt-4"> 
        <Tile label="Engine Export Value" color="blue">
          <div className="w-full max-w-[20rem]">
            {leadId ? 
              <AwaitingInventoryPrice vehicle={vehicle} leadId={leadId} />
              :
              <p className="font-bold text-center my-2 text-2xl  text-gray-900">{PriceFormat(vehicle.enginesWorldwideEngineOffer)}</p>
            }
          </div>
        </Tile>

        <Tile label="Front End Value" color="blue">
          <div className="w-full max-w-[20rem]">
            {leadId && <AwaitingInventoryVfePrice vehicle={vehicle} leadId={leadId} />}
          </div>
        </Tile>

        <Tile label="Total" value={PriceFormat(engineWorldWideTotal)} color="blue"/>
      </div>

      <div className="flex flex-row justify-center flex-wrap gap-4 pt-4"> 
        {
          vehicle.ebayEnginePrice ? <>
            <Tile label="Ebay Engine Avg" value={PriceFormat(vehicle.ebayEnginePrice)} color="teal"/>
            {
              engineData && (
                <>
                  <Tile label="Ebay Engine High" value={PriceFormat(engineData.highPriceRange)} color="teal"/>
                  <Tile label="Ebay Engine Demand" value={`${(engineData.demand * 100).toFixed(2).toString()}%`} color="teal"/>
                </>
              )
            
            }

          </>
          :
          <Tile label="Ebay Engine Avg" value="N/A" color="teal"/>
        }

        <Tile label="Gearbox Avg Price" value={PriceFormat(gearboxPrice)} color="teal"/>
        <Tile label="Gearbox Avg Margin" value={PriceFormat(gearboxMargin)} color="teal"/>
        <Tile label="Total" value={PriceFormat(ebayEngineAndGearbox)} color="teal"/>

      </div>

      <div className="flex flex-row justify-center flex-wrap gap-4 pt-4"> 

        <Tile label="Parts Oppurtunities" value={totalParts.toString()} color="green"/>
        <Tile label="Parts Price Value" value={PriceFormat(totalPartsPrice)} color="green"/>
        <Tile label="Parts Margin Value" value={PriceFormat(totalPartsMargin)} color="green"/>

      </div>

      <div className="flex flex-row justify-center flex-wrap gap-4 pt-4"> 
      
        <Tile label="Select Top Categories" color="violet">
          <div className="flex flex-col gap-y-1">
            <ButtonInput label={"Top 10"} isSubmit={false} onClick={() => setTopCategories(10)} classes={"w-20"} />
            <ButtonInput label={"Top 20"} isSubmit={false} onClick={() => setTopCategories(20)} classes={"w-20"} />
          </div>
        </Tile>

        {
          selected.length == 0 ? 
          <Tile label="Selected Categories" value="0" color="violet"/>
          :
          <>
            <Tile label="Selected Categories" color="violet" value={selected.length.toString()}>
              <ButtonInput label={"Clear"} isSubmit={false} onClick={() => setTopCategories(0)} classes={"!bg-red-600 hover:!bg-red-700"} />
            </Tile>
            <Tile label="Selected Time" value={`${Math.floor(selectedTime / 60)} hours`} color="violet">
              <p className="font-bold text-center my-2 text-xl text-violet-600">{selectedTime % 60} minutes</p>
            </Tile>
            <Tile label="Selected Price" value={PriceFormat(selectedPrice)} color="violet"/>
            <Tile label="Selected Margin" value={PriceFormat(selectedMargin)} color="violet"/>
          </>
        }

      </div>

      <div className="flex flex-row justify-center flex-wrap gap-4 pt-4"> 

        <Tile label="Total Salvage Profit" value={PriceFormat(profit)} color="pink"/>
        <Tile label="Total Export Profit" value={PriceFormat(engineWorldWideTotal)} color="pink"/>
        <Tile label="Total Parts Profit" value={PriceFormat(selectedMargin)} color="pink"/>
        <Tile label="Parts + Export Profit" color="pink">
          <p className="font-bold text-center my-2 text-2xl text-pink-600">{PriceFormat(selectedAndExportMargin)}</p>
          <div className="">
            <PopOutBox classes={"!w-96"} >
              <p className="text-lg font-medium text-slate-900">This is parts selected minus the Engine, Gearbox, Alternator, Turbo and Air Con Compressor.</p>
              <p className="text-lg font-medium text-slate-900">These parts are lost in the export.</p>
            </PopOutBox>
          </div>
        </Tile>

      </div>

    </Card>
  )

}