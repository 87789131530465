import { useMutation } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import { SearchRequest } from "../models/search/SearchRequest";
import { DefaultResponseWithData } from "./../models/system";
import { SearchResponse } from "./../models/search/SearchResponse";

export function useSearchMutations() {
  const { post } = useApiHelper();

  const search = useMutation({
    mutationFn: (data: SearchRequest) =>
      post<SearchRequest, DefaultResponseWithData<SearchResponse>>(
        "/utilities/search",
        data
      ),
  });

  return {
    search,
  };
}
