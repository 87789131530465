import Modal from "../../layouts/Modal";
import useEbayPromotions from "../../data/useEbayPromotions";
import LoadingWheel from "../shared/LoadingWheel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { IPart } from "../../models/Part";
import { IPromotion } from "../../models/Promotion";

interface PromotionPickerProps {
  open: boolean;
  setOpen: any;
  part: IPart | undefined;
}

export default function PromotionPicker({ open, setOpen, part }: PromotionPickerProps) {
  const { promotions, applyPromotion } = useEbayPromotions();

  const promotionClicked = (promo: IPromotion) => {
    applyPromotion.mutate({ part: part, promo: promo }, {
      onSuccess: () => {
        setOpen(false);
      }
    })
  }

  return <Modal open={open} setOpen={setOpen} width={"max-w-2xl"}>
    {
      promotions.isLoading || (promotions as any).data?.data?.response == null ?
        <LoadingWheel /> :
        <div>
          <h1 className="text-xl font-bold">Available Promotions</h1>
          <p className="text-red-600">Warning! Only one promotion can be applied at a time.</p><br />
          {/*<p>{partId}</p>*/}
          {
            (promotions as any).data?.data?.response?.promotions?.map((promo: any, i: number) =>
              <div className="flex flex-row" key={i}>
                {
                  part?.promotionId != promo.promotionId ?
                    <FontAwesomeIcon className="rounded-full p-1 cursor-pointer hover:ring-2 hover:ring-blue-500"
                      icon={faCheck}
                      onClick={e => promotionClicked(promo)}
                    />
                    :
                    <FontAwesomeIcon className="rounded-full p-1 cursor-pointer hover:ring-2 hover:ring-blue-500"
                      icon={faCircleCheck}
                    />
                }

                <p>{promo.name}</p>
              </div>)
          }
        </div>
    }
  </Modal>
}