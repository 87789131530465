import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import openInNewTab from "../../utils/openInNewTab";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { useSearchMutations } from "../../data/useSearchMutations";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import {
  SearchResponse,
  SearchResponseItem,
} from "../../models/search/SearchResponse";

interface SearchResultsTableProps {
  title: string;
  results: SearchResponseItem[];
  onRowClick: (id: string) => void;
}

function SearchResultsTable({
  title,
  results,
  onRowClick,
}: SearchResultsTableProps) {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">{title}</h2>
      <table className="min-w-full overflow-scroll divide-y border bg-white divide-gray-300">
        <thead>
          <tr className="bg-gp-blue-50 text-gray-800">
            <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left">
              Title
            </th>
          </tr>
        </thead>
        <tbody>
          {results && results.length > 0 ? (
            results.map((r) => (
              <tr
                onClick={() => onRowClick(r.id)}
                className="hover:bg-gray-100 hover:cursor-pointer"
              >
                <td className="px-3 py-3 border text-sm">{r.name}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                width="100%"
                className="py-4 text-center italic text-gray-600"
              >
                No results
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default function Search() {
  const { setInfo } = usePageNameContext();
  const location = useLocation();
  const { search } = useSearchMutations();
  const [results, setResults] = useState<SearchResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleRowClick = (id: string, category: keyof SearchResponse) => {
    switch (category) {
      case "leads":
      case "vehicles":
      case "contacts":
        //leads
        //vehicle
        //contacts
        openInNewTab(`/leads/${id}`);
        break;
      case "orders":
      case "parts":
        //orders
        //parts
        openInNewTab(`/stock/${id}`);
        break;
      default:
        console.warn("No action defined for category");
        break;
    }
  };

  useEffect(() => {
    setInfo({
      name: "Search",
      desc: "View all your search results sorted by type",
    });

    // Take search parameters from router and send to API
    search.mutate(location.state, {
      onSuccess: (result) => {
        setResults(result.data);
      },
      onError: (e) => {
        setError(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  }, [location]);

  if (loading) {
    return (
      <div className="flex items-center justify-center py-10">
        <LoadingWheel />
      </div>
    );
  } else if (error || results === null || results === undefined) {
    return (
      <div>
        <div className="mt-10 p-4 rounded-lg bg-red-100 border border-red-600 text-red-600">
          <p className="text-lg font-bold">Error</p>
          <p>An error occurred while performing the search</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="mt-10 space-y-10">
        {Object.keys(results).map((k) => (
          <SearchResultsTable
            key={k}
            title={capitalizeFirstLetter(k)}
            results={results[k as keyof SearchResponse]}
            onRowClick={(id) => handleRowClick(id, k as keyof SearchResponse)}
          />
        ))}
      </div>
    );
  }
}
