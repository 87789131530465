import { useState } from "react";
import usePartsLookup from "../../../../data/usePartsLeadLookup";
import Card from "../../../shared/Card/Card";
import { ButtonInput } from "../../../shared/Inputs/ButtonInput";
import LoadingWheel from "../../../shared/LoadingWheel";
import PriceFormat from './../../../../utils/priceFormat';


export default function PartsLookup({ leadId, requestId }: { leadId: string, requestId: string | null | undefined }) {
  const { lookup, lookupMutation } = usePartsLookup(leadId, requestId);
  const [isLoading, setIsLoading] = useState(false);

  const handleLookup = () => {
    setIsLoading(true);
    lookupMutation.mutate(undefined,
      {
        onSettled: () => setIsLoading(false)
      }
    );
  }

  if ((requestId && lookup.isLoading) || isLoading) {
    return (
      <Card title="Part Lookup Information" bodyClassName="p-4">
        <LoadingWheel />
      </Card>
    )
  }

  return (
    <Card title="Part Lookup Information" bodyClassName="p-4">
      <div className="grid lg:grid-cols-3">
        {lookup.data?.data?.requestedParts?.sort((a, b) => a.partType.localeCompare(b.partType)).map((part) => (
          <div className="text-gray-600 border p-4" key={part.partNumber}>
            <div className="text-black font-semibold">Part Type: {part?.partType}</div>
            <div>Part Number: {part?.partNumber}</div>
            {part.priceInformation.avgPrice ? (
              <>
                <div>Min Price: {PriceFormat(part?.priceInformation.minPrice)}</div>
                <div>Max Price: {PriceFormat(part?.priceInformation.maxPrice)}</div>
                <div>Average Price: {PriceFormat(part?.priceInformation.avgPrice)}</div>
                <div>Confidence: {part?.priceInformation.confidence}</div>
              </>
            ) : (
              <div>Used Price Information Not Available</div>
            )}
            <div>New Price: {PriceFormat(part?.priceInformation.manufacturerPrice)}</div>

          </div>
        ))}

        <div className="lg:col-span-3 mt-4">
          <ButtonInput label="Lookup Part Pricing" isSubmit={false} onClick={handleLookup} classes="" />
        </div>
      </div>
    </Card>
  )
}