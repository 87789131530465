import { useEffect, useState } from "react";

import { IPart } from "../../models/Part";
import classNames from "../../utils/classNames";
import useNotification from "../notifications/useNotifications";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import usePartMutations from "../../data/usePartMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useOffers from "../../data/useOffers";
import { FormatType, ListingDuration } from "../../models/EbayOffer";
import { TextInputWithValue } from "../shared/Inputs/TextInputWithValue";
import { TextInput } from "../shared/Inputs/TextInput";

interface EbayListingTitleProps {
  part: IPart;
  title?: string;
  table?: boolean;
  register?: any;
  getValues?: any;
  setValue?: any;
  watch?: any;
};

type IDefaults = {
  label: string,
  value: any,
  dataClass: string,
  tailwindClass: string
};

export default function EbayListingTitle({ part, title, table = false, register, getValues, setValue, watch }: EbayListingTitleProps) {
  const { patchPart } = usePartMutations();
  const { create, update } = useOffers();
  const { saveHandlers } = useDefaultCRUDHandlers("Part Title");
  const { addNotification } = useNotification();
  const [currentTitle, setCurrentTitle] = useState<string>(table ? title : getValues("title") || "");
  const [rawTitle, setRawTitle] = useState<string>(table ? title : getValues("title") || "");
  const [titleLabel, setTitleLabel] = useState(`Listing Title ${currentTitle?.length > 0 ? `(${currentTitle?.length}/80)` : ""}`);

  useEffect(() => {
    setTitleLabel(`Listing Title ${currentTitle?.length > 0 ? `(${currentTitle?.length}/80)` : ""}`);
  }, [currentTitle]);

  const formatEngineCapacity = (engine: number | null | undefined) => {
    if (!engine) return engine;

    let rounded = Math.round(engine / 100) * 100;
    let formatted = (rounded / 1000).toFixed(1);
    return formatted + "L";
  }

  //I know this looks dumb but it doubles up a null check to avoid ugly ternary operators in the defaults object 👍
  const formatSuffix = (value: string | number | null | undefined, suffix: string) => {
    if (!value) return value;

    return value + suffix;
  }

  const [defaults, setDefaults] = useState<IDefaults[]>([
    { label: "Category Name", value: part.title, dataClass: "data-yellow", tailwindClass: "bg-yellow-300" },
    { label: "Year", value: part.vehicle?.dvlaYearOfManufacture, dataClass: "data-green", tailwindClass: "bg-green-300" },
    { label: "Make", value: part.vehicle?.combinedMake, dataClass: "data-blue", tailwindClass: "bg-blue-300" },
    { label: "Model", value: part.vehicle?.combinedModel, dataClass: "data-orange", tailwindClass: "bg-orange-300" },
    { label: "MK", value: part.vehicle?.modelSeries, dataClass: "data-lime", tailwindClass: "bg-lime-300" },
    { label: "Fuel Type", value: part.vehicle?.fuelType, dataClass: "data-teal", tailwindClass: "bg-teal-300" },
    { label: "Door", value: formatSuffix(part.vehicle?.numberOfDoors, " Doors"), dataClass: "data-cyan", tailwindClass: "bg-cyan-300" },
    { label: "Body Style", value: part.vehicle?.bodyStyleDescription, dataClass: "data-indigo", tailwindClass: "bg-indigo-300" },
    { label: "Engine Capacity", value: formatEngineCapacity(part.vehicle?.engineCapacity), dataClass: "data-slate", tailwindClass: "bg-slate-300" },
    { label: "Engine Code", value: part.vehicle?.engineModelCode, dataClass: "data-purple", tailwindClass: "bg-purple-300" },
    { label: "BHP", value: formatSuffix(part.vehicle?.maximumPowerBHP, " BHP"), dataClass: "data-fuchsia", tailwindClass: "bg-fuchsia-300" },
    { label: "No. Gears", value: formatSuffix(part.vehicle?.combinedForwardGears, " Speed"), dataClass: "data-pink", tailwindClass: "bg-pink-300" },
    { label: "Transmission", value: part.vehicle?.combinedTransmission, dataClass: "data-rose", tailwindClass: "bg-rose-300" },
    { label: "Mileage", value: part.vehicle?.odometerInMiles === 0 ? null : part.vehicle?.odometerInMiles, dataClass: "data-amber", tailwindClass: "bg-amber-300" },
  ]);

  const handleSetValue = (name: string, value: any) => {
    if (!table) {
      setCurrentTitle(value);
      setValue(name, value);
    } else {
      setCurrentTitle(value);
    }
  };

  const handleSaveTitle = () => {
    //Need to save the offer title, not the part
    let offer = part.offers[0];

    let updatedOffer = {
      ...offer,
      title: currentTitle
    };

    if (offer) {
      update.mutate(updatedOffer, saveHandlers);
    } else {
      updatedOffer.partDataId = part.id;
      updatedOffer.format = FormatType.FixedPrice;
      updatedOffer.duration = ListingDuration.GTC;

      create.mutate(updatedOffer, saveHandlers);
    }
  };


  const changeTitle = (def: IDefaults) => {
    let newTitle = currentTitle;

    if (!def.value) {
      addNotification({
        variant: "info",
        primaryText: "No value found",
      })
      return;
    }

    if (!newTitle) {
      handleSetValue("title", def.value.toString());
      return;
    }

    let word = def.value.toString();
    // Escape special characters in the word for the regex
    const escapedWord = word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    // Create a case-insensitive regex to match the exact phrase
    const regex = new RegExp(escapedWord, "i");

    if (regex.test(newTitle)) {
      // Remove the value, ensuring spaces are handled correctly
      newTitle = newTitle.replace(regex, "").trim().replace(/\s{2,}/g, " ");
      handleSetValue("title", newTitle);
    } else {
      // Add the value
      newTitle = `${newTitle} ${word}`.trim().replace(/\s{2,}/g, " ");
      handleSetValue("title", newTitle);
    }

  };

  useEffect(() => {
    let title = currentTitle;

    // check if title includes any of the default values
    defaults.forEach((def) => {
      if (title?.toLowerCase().includes(def.value?.toString().toLowerCase())) {
        // replace the spans with something that isn't accidently going to be ovwerwritten
        // we had an issue with the MK being PA and it was replacing the PA in span
        title = title?.toUpperCase().replaceAll("SPAN", "¬¬¬¬").replace(def.value?.toString().toUpperCase(), `<span ${def.dataClass}>${def.value}</span>`).replaceAll("¬¬¬¬", "span");
      }
    });

    setRawTitle(title);
  }, [part, currentTitle]);

  useEffect(() => {
  }, [currentTitle, part, title]);

  useEffect(() => {
    if (!currentTitle) {
      //Default title if empty
      let title = `${part.vehicle?.dvlaYearOfManufacture} ${part.vehicle?.combinedMake} ${part.vehicle?.combinedModel} ${part.title}`;
      setRawTitle(title);
      setCurrentTitle(title);
    };
  }, []);

  return (
    <>
      {/* Table of title fields */}
      <div className="col-span-2 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
        {defaults.map((def, i) => {
          let exists = false;

          if (currentTitle) {
            //Only check if the default is in the title if the title is not empty
            exists = currentTitle?.toLowerCase().includes(def.value?.toString().toLowerCase());
          }

          // this doesn't work because of Tailwind tree shake or something
          // let existsClass = exists ? `bg-${def.colour}-300` : "";
          let existsClass = exists ? def.tailwindClass : "";
          return (
            <div
              key={i}
              className={classNames(existsClass, " text-center border border-gray-300 p-2 cursor-pointer hover:bg-gray-200 select-none")}
              onClick={() => changeTitle(def)}
            >
              <span>{def.label}</span>
            </div>
          );
        })}
      </div>

      {register ? (
        <TextInputWithValue
          label={titleLabel}
          register={register ?? {}}
          registerName={"title"}
          cols="sm:col-span-2"
          disabled={part.soldDate !== null}
          value={currentTitle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentTitle(e.target.value)}
        />
      ) : (
        <div className="sm:col-span-2 w-full flex gap-x-4 py-2">
          <div className="flex flex-col gap-y-2 w-1/2">
            <label className="text-sm font-medium text-gray-500">Listing Title {currentTitle?.length > 0 ? `(${currentTitle?.length}/80)` : ""}</label>
            <input
              type="text"
              className="w-full flex-1 text-sm text-gray-900 border-1 border-gray-400"
              value={currentTitle}
              onChange={(e) => handleSetValue("title", e.target.value)}
            />
          </div>
          <div className="pt-7">
            <ButtonInput label="Save" isSubmit={false} onClick={() => handleSaveTitle()} classes={"py-3"} />
          </div>
        </div>
      )}

      {/* <label className="block text-sm font-medium text-gray-700 col-span-2">{rawTitle}</label> */}
      <div
        className="block text-sm col-span-2 
        [&>[data-green]]:bg-green-300
        [&>[data-lime]]:bg-lime-300
        [&>[data-teal]]:bg-teal-300
        [&>[data-cyan]]:bg-cyan-300
        [&>[data-indigo]]:bg-indigo-300
        [&>[data-slate]]:bg-slate-300
        [&>[data-purple]]:bg-purple-300
        [&>[data-fuchsia]]:bg-fuchsia-300
        [&>[data-pink]]:bg-pink-300
        [&>[data-rose]]:bg-rose-300
        [&>[data-emerald]]:bg-emerald-300
        [&>[data-amber]]:bg-amber-300
        [&>[data-blue]]:bg-blue-300
        [&>[data-orange]]:bg-orange-300
        [&>[data-yellow]]:bg-yellow-300"
        dangerouslySetInnerHTML={{ __html: rawTitle }} ></div>
    </>
  )
}