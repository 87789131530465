import { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import Avatar from "../../user/Avatar";
import classNames from "../../../utils/classNames";
import { useAuthApi } from "../../../hooks/useAuthApi";
import { Search } from "./Search";

export interface TopbarProps {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Topbar({ setSidebarOpen }: TopbarProps) {
  const { logout } = useAuthApi();

  const handleLogout = async () => {
    // TODO: impl logout with sso
    // For now manual cookie deletion
    // // Delete the cookie by setting an expiration date in the past
    // document.cookie = "WilmaAuthToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // // Optionally redirect to a login page or home page after logout
    // window.location.href = "/"; // Update with the correct path for your app
    logout().then(() => {
      window.location.href = "/";
    });
  };

  const passwordReset = () => {
    //TODO: impl password reset
  };

  const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Reset password", onClick: passwordReset },
    { name: "Sign out", onClick: handleLogout },
  ];

  return (
    <div className="sticky top-0 z-30 flex h-16 flex-shrink-0 bg-white shadow">
      <button
        type="button"
        className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gp-blue-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex flex-1 justify-between px-4">
        <div />
        {/* <Search /> */}
        {/* <div className="flex flex-1"> */}
          {/* <form className="flex w-full md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                placeholder="Search"
                type="search"
                name="search"
              />
            </div>
          </form> */}
          {/* <div className="">
            <ArrowLeftCircleIcon className="w-16 h-16 text-gp-blue-600 absolute hover:cursor-pointer"
              onClick={() => navigate(-1)} />
          </div> */}
        {/* </div> */}
        <div className="ml-4 flex items-center md:ml-6">
          {/* <button
            type="button"
            className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gp-blue-500 focus:ring-offset-2"
          >
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button> */}

          {/* Profile dropdown */}
          <Menu as="div" className="relative ml-3">
            <div>
              <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-gp-blue-500 focus:ring-offset-2">
                <span className="sr-only">Open user menu</span>
                <Avatar classes="h-9 w-9" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        // href={item.href}
                        onClick={item.onClick}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                        )}
                      >
                        {item.name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
