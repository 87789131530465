import { useEffect, useState } from "react";

import { useDebounce } from "@uidotdev/usehooks";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { ILead } from "../../models/Lead";
import LoadingWheel from "../../components/shared/LoadingWheel";
import DashboardTile from "../../components/dashboard/DashboardTile";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import { PartsStatusDropdowns, PartsStatusValues } from "../../models/Vehicle";
import CustomDatePicker from "../../components/awaitingInventory/CustomDatePicker";
import useLeadsForVehiclesAwaitingArrival from "../../data/useLeadsForVehiclesAwaitingArrival";
import AwaitingArrivalTable from "../../components/vehiclesAwaitingArrival/AwaitingArrivalTable";
import { DateTime } from "luxon";

type DateRangeType = {
  startDate: string | null;
  endDate: string | null;
};

export default function VehiclesAwaitingArrival() {
  const { setInfo } = usePageNameContext();
  const [leads, setLeads] = useState<ILead[] | undefined>(undefined);
  const [collectedToday, setCollectedToday] = useState<number | undefined>(
    undefined
  );
  const [awaitingCollection, setAwaitingCollection] = useState<
    number | undefined
  >(undefined);
  const [dropOff, setDropOff] = useState<number>();
  const [starts, setStarts] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentStatusFilter, setCurrentStatusFilter] = useState<string>("All");
  const [dates, setDates] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const debouncedSearch = useDebounce(searchTerm, 500);

  const { leadsForVehiclesAwaitingArrival } =
    useLeadsForVehiclesAwaitingArrival({
      query: debouncedSearch,
      start: DateTime.fromJSDate(dates.startDate).toISODate(),
      end: DateTime.fromJSDate(dates.endDate).toISODate(),
    });

  const partsStatus = [
    ...Object.values(PartsStatusDropdowns).filter((ct) => isNaN(Number(ct))),
  ];

  const handleDatesChange = (newDates: any) => {
    // console.log("newValue:", newDates);
    setDates(newDates);
  };

  useEffect(() => {
    setInfo({
      name: "Vehicles Awaiting Arrival",
      desc: "View and manage vehicles being picked up today.",
    });
  }, []);

  useEffect(() => {
    if (!leadsForVehiclesAwaitingArrival.isLoading) {
      let filter = leadsForVehiclesAwaitingArrival.data?.data.leads;

      if (currentStatusFilter !== "All") {
        filter = filter?.filter(
          (lead) => PartsStatusValues[lead.vehicle?.engineExportStatus!] === currentStatusFilter);
      }

      setCollectedToday(
        filter?.filter(
          (lead) => lead.vehicle?.collectedOn !== null && !lead.vehicle?.isDropOff)?.length
      );
      setAwaitingCollection(
        filter?.filter(
          (lead) => lead.vehicle?.collectedOn === null && !lead.vehicle?.isDropOff)?.length
      );
      setStarts(
        filter?.filter(
          (lead) => lead.vehicle?.vehicleStarting === true)?.length
      );

      setDropOff(filter?.filter((l) => l.vehicle?.isDropOff).length);

      //find any collected leads and move to the top
      const topLeads = filter?.filter((lead) => lead.vehicle?.collectedOn);
      const restOfLeads = filter?.filter((lead) => !lead.vehicle?.collectedOn);

      filter = topLeads?.concat(restOfLeads!);

      setLeads(filter);
      setIsLoading(false);
    }
  }, [
    leadsForVehiclesAwaitingArrival.isLoading,
    leadsForVehiclesAwaitingArrival.data,
    currentStatusFilter,
    dates,
  ]);

  return (
    <>
      <div className="mt-4 grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5">
        <DashboardTile>
          <div className="h-full rounded bg-blue-100 text-blue-600 p-2 px-4 prose-base">
            <h1 className="text-blue-600 font-semibold">{leads?.length}</h1>
            <p className="!m-0">Arriving Today</p>
          </div>
        </DashboardTile>
        <DashboardTile>
          <div className="h-full rounded bg-green-100 text-green-600 p-2 px-4 prose-base">
            <h1 className="text-green-600 font-semibold">{collectedToday}</h1>
            <p className="!m-0">Collected Today</p>
          </div>
        </DashboardTile>
        <DashboardTile>
          <div className="h-full rounded bg-amber-100 text-amber-600 p-2 px-4 prose-base">
            <h1 className="text-amber-600 font-semibold">
              {awaitingCollection}
            </h1>
            <p className="!m-0">Awaiting Collection</p>
          </div>
        </DashboardTile>
        <DashboardTile>
          <div className="h-full rounded bg-purple-100 text-purple-600 p-2 px-4 prose-base">
            <h1 className="text-purple-600 font-semibold">{starts}</h1>
            <p className="!m-0">Starts & Drives</p>
          </div>
        </DashboardTile>
        <DashboardTile>
          <div className="h-full rounded bg-teal-100 text-teal-600 p-2 px-4 prose-base">
            <h1 className="mb-1 font-semibold">Parts Status</h1>
            <ul className="p-0 text-sm">
              {partsStatus.map((status, i) => (
                //@ts-ignore
                <li key={i} className="!m-0">
                  {status}:{" "}
                  {
                    leads?.filter(
                      (lead) =>
                        lead.vehicle?.engineExportStatus ===
                        //@ts-ignore
                        PartsStatusValues[status]
                    )?.length
                  }
                </li>
              ))}
            </ul>
          </div>
        </DashboardTile>
        <DashboardTile>
          <div className="h-full rounded bg-rose-100 text-rose-600 p-2 px-4 prose-base">
            <h1 className="text-rose-600 font-semibold">{dropOff}</h1>
            <p className="!m-0">Awaiting Drop Off</p>
          </div>
        </DashboardTile>
      </div>
      <div className="mt-4 flex justify-between">
        <div className="flex gap-x-5">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 sm:text-sm"
              placeholder="Search any vehicle details"
            />
          </div>

          <div>
            <select
              aria-label="Lead status filter"
              onChange={(e) => setCurrentStatusFilter(e.target.value)}
              value={currentStatusFilter}
              className="sm:text-sm"
            >
              <option className="sm:text-sm" key={"All"}>
                All
              </option>
              {partsStatus.map((status) => (
                <option className="sm:text-sm" key={status}>
                  {String(status)}
                </option>
              ))}
            </select>
          </div>

          <div>
            <CustomDatePicker
              dates={dates}
              handleDatesChange={handleDatesChange}
            />
          </div>
        </div>
      </div>

      {leadsForVehiclesAwaitingArrival.isLoading || isLoading ? (
        <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
          <LoadingWheel />
        </div>
      ) : (
        <AwaitingArrivalTable leads={leads} />
      )}
    </>
  );
}
