import { DateTime } from "luxon";
import Datepicker from "react-tailwindcss-datepicker";

export default function CustomDatePicker({ dates, handleDatesChange }) {

  //**  This library is broken - can't use custom shortcuts in a .ts file!  **/

  return (
    <Datepicker
      inputClassName="text-sm border-gray-500 rounded-none w-64"
      toggleClassName="absolute text-black font-bold right-0 h-full px-3"
      containerClassName=""
      placeholder="Filter by date range"
      showShortcuts={true}
      useRange={false}
      value={dates}
      onChange={newValue => {
        if (newValue.startDate === null && newValue.endDate === null) { 
          handleDatesChange({startDate: null, endDate: null});
          return;
        };

        const newStartDate = new Date(newValue.startDate)
        const newEndDate = new Date(newValue.endDate)

        handleDatesChange({startDate: newStartDate, endDate: newEndDate})
      }}
      displayFormat="DD/MM/YYYY"
      configs={{
        shortcuts: {
          today: "Today",
          tomorrow: {
            text: "Tomorrow",
            daysNumber: 1,
            period: {
              start: DateTime.now().plus({ days: 1 }).toJSDate(),
              end: DateTime.now().plus({ days: 1 }).toJSDate()
            },
          },
          yesterday: "Yesterday",
          past: period => `Past ${period} days`,
          nextThreeDays: {
            text: "Next 3 Days",
            daysNumber: 3,
            period: {
              start: DateTime.now().toJSDate(),
              end: DateTime.now().plus({ days: 3 }).toJSDate()
            },
          },
          nextSevenDays: {
            text: "Next 7 Days",
            daysNumber: 7,
            period: {
              start: DateTime.now().toJSDate(),
              end: DateTime.now().plus({ days: 7 }).toJSDate()
            },
          },
          currentMonth: "This Month",
          pastMonth: "Last Month",
          nextMonth: {
            text: "Next Month",
            period: {
              start: DateTime.now().plus({ months: 1 }).startOf("month").toJSDate(),
              end: DateTime.now().plus({ months: 1 }).endOf("month").toJSDate()
            },
          },
        }
      }}
    />
  )
}